<template>
  <project-overview></project-overview>
</template>

<script>
import projectOverview from "@/components/projectOverview/projectOverviewGC";
export default {
  name: "",
  data() {
    return {};
  },
  components: { projectOverview },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped></style>