<template>
  <div class="projectoverviewGC">
    <div class="top">
      <div class="left">
        <div class="TotalConsumption">
          <div class="title">能耗总量指标</div>
          <div class="content">
            <div class="bmxh">
              <img src="~@/assets/projectoverallGC/11.webp" alt="" class="bgimg">
              <div class="p1">{{consumenum.toFixed(1)}}</div>
              <div class="p2">tce</div>
              <div class="p3">当年标煤消耗</div>
            </div>
            <div class="qntb">
              <div>
                <div class="p1">
                <img src="~@/assets/projectoverallGC/三角形.png" alt="">
                6.5 <span>%</span>
              </div>
              <div class="p2">去年同比</div>
              </div>
            </div>
          </div>
          <div class="floor">
            <div class="prigre">
              <div class="progress">
                <div class="jindu" :style="{width:nxe + '%'}">
                  <div class="liangd"></div>
                </div>
              </div>
              <div class="txt">进度: <span>{{nxe}}%</span></div>
            </div>
            <div>
              <span class="p1">年限额</span>
              <span class="p2">{{(4547.9 * 2.5).toFixed(1)}}</span>
              <span class="p3">tce</span>
            </div>
          </div>
        </div>
        <div class="CompanyConsumption">
          <div class="title">单位能耗指标</div>
          <div class="content">
            <div class="left">
              <div class="headertxt">产品单耗</div>
              <div class="l3cont">
                <img src="~@/assets/projectoverallGC/12.webp" alt="">
                <div class="l3contP">
                  <div class="p1">23.3</div>
                  <div class="p2">kgce/t</div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="headertxt">产值单耗</div>
              <div class="l3cont">
                <img src="~@/assets/projectoverallGC/13.webp" alt="">
                <div class="l3contP">
                  <div class="p1">20.5</div>
                  <div class="p2">kgce/万元</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="GreenLowCarbon">
          <div class="title">绿色低碳指标</div>
          <div class="contentG">
            <div class="mode1">
              <div class="titleG">单位GDP CO2排放</div>
              <div class="mode1content">
                <div class="contentGps">
                  <div class="Gp1">当年 <span class="pt1">53.4</span><span class="pt2">kgce</span></div>
                  <div class="Gp2">限值 <span class="pt1">71.3</span><span class="pt2">kgce</span></div>
                </div>
                <div id="progressecharts"></div>
              </div>
            </div>
            <div class="mode2">
              <div class="titleG">新能源利用率</div>
              <div class="mode2content">
                <div class="m2conts">
                  <div class="m2left">
                    <div class="p1">{{(consumenum * 0.065).toFixed(1)}}</div>
                    <div class="p2">新能源 <span>tce</span></div>
                  </div>
                  <div class="m2right">
                    <div class="p1">{{consumenum.toFixed(1)}}</div>
                    <div class="p2">总能耗 <span>tce</span></div>
                  </div>
                </div>
                <div id="progressecharts2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="headercenter">
        <div class="headeritem">
          <div class="contentnum">{{ssfh}}<span class="Company">kW</span></div>
          <div class="headimg">
            实时负荷
          </div>
        </div>
        <div class="headeritem">
          <div class="contentnum">1253.2<span class="Company">tce</span></div>
          <div class="headimg">
            累计节能
          </div>
        </div>
        <div class="headeritem">
          <div class="contentnum">303.7<span class="Company">万元</span></div>
          <div class="headimg">
            累计节费
          </div>
        </div>
        <div class="headeritem">
          <div class="contentnum">{{(1.35 * 1253).toFixed(1)}}<span class="Company">t</span></div>
          <div class="headimg">
            累计减排（CO₂）
          </div>
        </div>
      </div>
      <div class="right">
        <div class="jnzl">
          <div class="title">节能总览</div>
          <div class="jncontent">
            <div class="fyleft">
              <div class="p1">126.6 <span class="p2">万元</span></div>
              <div class="p3">节能费用</div>
            </div>
            <div class="lvright">
              平均节能率 <span class="p1">4.3<span class="p2">%</span></span>
            </div>
          </div>
        </div>
        <div class="fhyc">
          <div class="title">负荷预测</div>
          <div id="LoadForecasting"></div>
        </div>
        <div class="nylx">
          <div class="title">能源流向</div>
          <div id="EnergyFlowDirection"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="header">
          <div class="title">能源单耗指标</div>
          <div class="tabs">
            <div class="seltab">
              <div class="tab" @click="seltype = 1" :class="seltype == 1 ? 'selthis' : ''">挤压</div>
              <div class="tab" @click="seltype = 2" :class="seltype == 2 ? 'selthis' : ''">泡磨</div>
            </div>
          </div>
        </div>
        <div class="botmcont">
          <div class="acont">
            <div class="tophead">
              <img src="~@/assets/projectoverallGC/c@2x.png" alt="" class="imgh">
              用电(kWh/t)
            </div>
            <div class="contcont">
              <img src="~@/assets/heatingSystemWebp/used0.webp" alt="">
            </div>
            <div class="context">{{seltype == 1 ? 64.6 : (64.6 * 0.63).toFixed(1)}}</div>
          </div>
          <div class="acont">
            <div class="tophead">
              <img src="~@/assets/projectoverallGC/c@2x.png" alt="" class="imgh">
              用水(m³/t)
            </div>
            <div class="contcont">
              <img src="~@/assets/heatingSystemWebp/used1.webp" alt="">
            </div>
            <div class="context">{{seltype == 1 ? '0.1' : '0.3'}}</div>
          </div>
          <div class="acont">
            <div class="tophead">
              <img src="~@/assets/projectoverallGC/c@2x.png" alt="" class="imgh">
              燃气(m³/t)
            </div>
            <div class="contcont">
              <img src="~@/assets/heatingSystemWebp/used2.webp" alt="">
            </div>
            <div class="context">{{seltype == 1 ? '0.2' : '0.3'}}</div>
          </div>
          <div class="acont">
            <div class="tophead">
              <img src="~@/assets/projectoverallGC/c@2x.png" alt="" class="imgh">
              蒸汽(MJ/t)
            </div>
            <div class="contcont">
              <img src="~@/assets/heatingSystemWebp/used3.webp" alt="">
            </div>
            <div class="context">{{seltype == 1 ? '43.1' : '71.4'}}</div>
          </div>
          <div class="acont">
            <div class="tophead">
              <img src="~@/assets/projectoverallGC/c@2x.png" alt="" class="imgh">
              新能源(kWh/t)
            </div>
            <div class="contcont">
              <img src="~@/assets/heatingSystemWebp/used5.webp" alt="">
            </div>
            <div class="context">{{seltype == 1 ? 6.4 : (6.48 * 0.63).toFixed(1)}}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="header">
          <div class="title">能耗综合分析</div>
          <div class="tabs">
            <div class="seltab">
              <div class="tab" @click="clickseltab(1)" :class="seltab == 1 ? 'selthis' : ''">综合</div>
              <div class="tab" @click="clickseltab(2)" :class="seltab == 2 ? 'selthis' : ''">电</div>
              <div class="tab" @click="clickseltab(3)" :class="seltab == 3 ? 'selthis' : ''">水</div>
              <div class="tab" @click="clickseltab(4)" :class="seltab == 4 ? 'selthis' : ''">燃气</div>
              <div class="tab" @click="clickseltab(5)" :class="seltab == 5 ? 'selthis' : ''">蒸汽</div>
              <div class="tab" @click="clickseltab(6)" :class="seltab == 6 ? 'selthis' : ''">新能源</div>
            </div>
          </div>
        </div>
        <div class="chartMultiple">
          <v-chart ref="chartMultiple" :option="optionMultiple" />
        </div>
      </div>
    </div>
  </div>
</template>
src/assets/zgdsdialog.png
<script>
import * as echarts from "echarts";
export default {
  data(){
    return{
      seltype:1,
      seltab:1,
      optionMultiple: {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          itemWidth:20,
          itemHeight:10
        },
        grid: {
          top: "35px",
          left: "20px",
          right: "50px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          name: "",
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
          // 轴上的刻度设置
          axisLabel: {
            inside: false,
            interval: 0,
            textStyle: {
              // color: "#fff",
              color:'rgba(255,255,255,0.8)'
            },
            rotate:0
          },
          // 轴线样式设置
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisTick: {
            show: false,
            color:'rgba(255,255,255,0.8)'
          },
          // 单位设置
          nameTextStyle: {
            color: "#fff",
            verticalAlign: "top",
            align: "left",
          },
        },
        yAxis: {
          type: "value",
          name: '(kgce)',
          nameTextStyle:{
            // color:'#fff'
            color:'rgba(255,255,255,0.8)'
          },
          axisTick: {
            show: false,
            color:'rgba(255,255,255,0.8)'
          },
          // 轴上延申线
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63, 109, 248, 0.5)"],
              width: 1,
              type: "dashed",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisLabel: {
            color: "red",
            inside: false,
            textStyle: {
              // color: "#fff",
              color:'rgba(255,255,255,0.8)'
            },
            lineStyle: {
              color: "red",
              widtg: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            barWidth: 8,
            name: "今天",
            type: "bar",
            data: [
              
            ],
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#95A8FF" }, //柱图渐变色
                  { offset: 1, color: "#002DCF" }, //柱图渐变色
                ]),
                // barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#008BE5" }, //柱图渐变色
                  { offset: 1, color: "#9EFEFF" }, //柱图渐变色
                ]),
                // barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "昨天",
            type: "bar",
            barWidth: 8,
            data: [
              ],
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#9EFEFF" }, //柱图渐变色
                  { offset: 1, color: "#008BE5" }, //柱图渐变色
                ]),
                // barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#008BE5" }, //柱图渐变色
                  { offset: 1, color: "#9EFEFF" }, //柱图渐变色
                ]),
                // barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
        ],
      },
      setconsume:[359.56,
411.76,
359.36,
367.66,
355.61,
377.46,
366.02,
398.61,
356.02,
401.54,
391.93,
352.97],
      consumeArr:{
        zh:{
          td:[377.21,430.60,471.44,517.19,540.49,619.04,620.80,683.89,
          694.29,761.17,780.51,843.12,839.07,831.74,794.89,789.17,
          773.41,761.23,738.20,734.21,722.96,717.64,691.08,686.78],
          yd:[447.47,458.58,500.01,501.09,543.62,607.48,620.61,687.93,
          718.48,747.28,817.77,834.98,817.59,808.56,806.84,776.83,
          763.16,721.04,713.32,689.37,689.24,674.89,674.35,658.05]
        },
        d:{
          td:[
            1053.74,1138.01,1173.97,1182.30,1219.28,1258.46,1290.29,
            1327.96,1402.68,1403.60,1445.05,1477.45,1476.28,1437.76,
            1429.55,1403.62,1396.48,1388.88,1388.30,1368.62,1364.82,
            1362.63,1348.86,1340.76],
          yd:[
            1011.12,1078.57,1140.68,1163.99,1248.73,1285.55,1343.90,
            1418.76,1419.57,1463.89,1502.28,1540.80,1537.93,1537.75,
            1534.95,1495.22,1494.85,1489.51,1471.63,1436.89,1422.84,
            1412.52,1410.67,1403.71]
        },
        s:{
          td:[29.68,35.99,42.64,48.59,55.88,62.79,63.26,66.73,75.59,83.73,
          89.02,91.38,88.76,88.48,84.35,83.63,81.42,76.85,75.06,73.46,72.26,
          69.11,67.45,65.50],
          yd:[38.15,41.42,47.38,54.22,62.14,69.58,76.42,80.87,89.12,97.11,
          107.09,113.55,112.57,111.69,107.88,106.69,105.71,100.11,99.46,
          97.90,94.20,93.74,91.60,90.24]
        },
        rq:{
          td:[5.16,7.52,7.57,11.10,13.30,13.40,17.68,21.00,21.49,22.58,
          25.14,25.72,25.21,25.14,24.61,24.31,22.65,22.58,20.34,18.76,
          18.58,18.38,17.86,17.46],
          yd:[14.88,18.40,18.67,19.38,19.67,19.86,21.78,21.79,22.33,25.89,
          28.99,30.64,30.22,29.43,29.14,28.44,28.22,26.78,25.19,23.79,
          23.08,22.37,21.27,20.04]
        },
        zq:{
          td:[10.55,
12.41,
12.65,
13.01,
13.75,
15.56,
17.70,
18.82,
20.21,
21.75,
21.86,
24.75,
24.62,
24.10,
23.57,
23.38,
23.27,
22.28,
21.60,
21.59,
21.29,
20.17,
19.87,
19.20],
          yd:[12.89,14.36,14.87,16.07,17.49,19.81,19.98,21.77,24.66,24.82,
          25.25,26.63,25.87,25.36,25.35,25.23,24.56,24.21,22.93,21.28,
          20.73,20.54,20.07,19.77]
        },
        xny:{
          td:[],
          yd:[]
        }
      },
      consumenum:0,
      setYaxname: '(tec)',
      nxe: 0,
      shishi:[3377.8,
3450.8,
3470.4,
3515.7,
3666.0,
3844.5,
3916.7,
4068.7,
4225.2,
4293.2,
4472.5,
4519.6,
4496.4,
4486.4,
4455.6,
4387.4,
4348.6,
4347.4,
4340.6,
4238.1,
4191.3,
4159.0,
4119.1,
4063.4],
      yuce:[3354.2,
3374.7,
3395.7,
3475.3,
3504.7,
3578.0,
3630.3,
3679.5,
3793.4,
3829.6,
3852.9,
3955.0,
3937.9,
3934.2,
3931.1,
3887.4,
3806.5,
3784.4,
3779.7,
3775.9,
3769.0,
3755.3,
3747.7,
3699.1],
    ssfh:0
    }
  },
  mounted(){
    this.LoadForecasting()
    this.EnergyFlowDirection()
    this.progressecharts()
    this.progressecharts2()

    this.optionMultiple.series[0].data = this.filterdata(this.consumeArr.zh.td)
    this.optionMultiple.series[1].data = this.consumeArr.zh.yd
    this.$refs.chartMultiple.setOption(this.optionMultiple);

    let nowmonth =  new Date().getMonth()+1;
    this.setconsume.forEach((item,index)=>{
      if((index + 1) < nowmonth){
        this.consumenum += item
      }
    })
    this.consumenum = this.consumenum * 3
    this.nxe = ((this.consumenum / (4547.9 * 2.5) ) * 100).toFixed(1)
    this.setnum()
    this.ssfh = this.shishi[new Date().getHours()] 
  },
  methods:{
    setnum(){
      this.consumeArr.zh.td = this.consumeArr.zh.td.map(item=>{
        return item * 3
      })
      this.consumeArr.zh.yd = this.consumeArr.zh.yd.map(item=>{
        return item * 3
      })
      this.consumeArr.d.td = this.consumeArr.d.td.map(item=>{
        return (item * 3).toFixed(1)
      })
      this.consumeArr.d.yd = this.consumeArr.d.yd.map(item=>{
        return (item * 3).toFixed(1)
      })
      this.consumeArr.xny.td = this.consumeArr.d.td.map((item,index)=>{
        if(index < 8 || index > 19){
          return 0
        }else{
          return (item * 3 * 0.06).toFixed(1)
        }
      })
      this.consumeArr.xny.yd = this.consumeArr.d.yd.map((item,index)=>{
        if(index < 8 || index > 19){
          return 0
        }else{
          return (item * 3 * 0.06).toFixed(1)
        }
      })
    },
    clickseltab(index){
      this.seltab = index
      if(index == 1){
        this.optionMultiple.yAxis.name = '(kgce)'
        this.optionMultiple.series[0].data = this.filterdata(this.consumeArr.zh.td)
        this.optionMultiple.series[1].data = this.consumeArr.zh.yd
      }else if(index == 2){
        this.optionMultiple.yAxis.name = '(kWh)'
        this.optionMultiple.series[0].data = this.filterdata(this.consumeArr.d.td)
        this.optionMultiple.series[1].data = this.consumeArr.d.yd
      }else if(index == 3){
        this.optionMultiple.yAxis.name = '(m³)'
        this.optionMultiple.series[0].data = this.filterdata(this.consumeArr.s.td)
        this.optionMultiple.series[1].data = this.consumeArr.s.yd
      }else if(index == 4){
        this.optionMultiple.yAxis.name = '(m³)'
        this.optionMultiple.series[0].data = this.filterdata(this.consumeArr.rq.td)
        this.optionMultiple.series[1].data = this.consumeArr.rq.yd
      }else if(index == 5){
        this.optionMultiple.yAxis.name = '(GJ)'
        this.optionMultiple.series[0].data = this.filterdata(this.consumeArr.zq.td)
        this.optionMultiple.series[1].data = this.consumeArr.zq.yd
      }else if(index == 6){
        this.optionMultiple.yAxis.name = '(kWh)'
        this.optionMultiple.series[0].data = this.filterdata(this.consumeArr.xny.td)
        this.optionMultiple.series[1].data = this.consumeArr.xny.yd
      }
    },
    filterdata(arr){
      let num = new Date().getHours()
      console.log(num)
      // console.log(arr.map((item,index)=>{
      //   if(index < num){
      //     return item
      //   }else{
      //     return null
      //   }
      // }))
      return arr.map((item,index)=>{
        if(index < (num + 1)){
          return item
        }else{
          return null
        }
      })
    },
    EnergyFlowDirection(){
      const curveEchart = this.$echarts.init(
        document.getElementById('EnergyFlowDirection')
      )
      curveEchart.setOption(
        {
          tooltip: {
					trigger: 'item',
					borderWidth: 1,
					borderColor: 'rgba(0,0,0,.6)',
					padding: 0,
					textStyle: {
						color: '#ffffff',
					},
					formatter: (params) => {
            // console.log(params)
            let numarr = {
              d:25,
              s:8,
              q:11,
              r:10.5,
              xny:11
            }
            let num = 0
            if(params.data.source == '电'){
              num = numarr.d
            } else if(params.data.source == '水'){
              num = numarr.s
            } else if(params.data.source == '气'){
              num = numarr.q
            } else if(params.data.source == '热'){
              num = numarr.r
            } else if(params.data.source == '新能源'){
              num = numarr.xny
            }
            console.log(num)
						let str = `<div style="width: 100%; height: 100%;padding: 5px;box-sizing: border-box; background:linear-gradient(90deg,#000826,#1448DC)">
						  <div>
						    <span style="display: inline-block; width: 10px; height: 10px;border-radius: 5px;background-color: #76B8CD">
						    </span>
						    ${params.data.source}-${params.data.target}
						  </div>
              <div>
				        <span style="color:#5cf7ff; font-size: 18px;">
                ${(Number(params.data.value) / num * 100).toFixed(1)}%</span>
			        </div>
						</div>`;
						if (params.data.source) {
							return str;
						} else {
							return;
						}
					},
				},
          series: {
            type: 'sankey',
            layout: 'none',
            emphasis: {
              focus: 'adjacency',
            },
            itemStyle: {
              // borderWidth: 2,
              // borderColor: '#fff',
              // width: 1,
            },
            label: {
              fontFamily: 'Arial, Verdana, sans-serif',
              fontSize: 12,
              fontWeight: 400,
              // color: '#5FE955',
              color: '#fff',
            },
            levels: [
              {
                depth: 0,
                itemStyle: {
                  color: '#ff9000',
                },
                lineStyle: {
                  color: '#ff9000',
                  // opacity: 0.8,
                },
              },
            ],
            data: [
              {
                name: '水',
                itemStyle:{
                  color:'#E98C13'
                }
              },
              {
                name: '电',
                itemStyle:{
                  color:'#55E9BE'
                }
              },
              {
                name: '气',
                itemStyle:{
                  color:'#11DFEA'
                }
              },
              {
                name: '热',
                itemStyle:{
                  color:'#0091FF'
                }
              },
              {
                name: '新能源',
                itemStyle:{
                  color:'#947CFF'
                }
              },
              {
                name: '挤压车间',
                itemStyle:{
                  color:'#E9BAE8'
                }
              },
              {
                name: '泡磨车间',
                itemStyle:{
                  color:'#5B2FFF'
                }
              },
              {
                name: '空压系统',
                itemStyle:{
                  color:'#FFE588'
                }
              },
              {
                name: '暖通系统',
                itemStyle:{
                  color:'#FFC454'
                }
              },
              {
                name: '辅助生产',
                itemStyle:{
                  color:'#CC5BE2'
                }
              },
              {
                name: '办公生活',
                itemStyle:{
                  color:'#CF4E55'
                }
              }
            ],
            links: [
              {
                source: '水',
                target: '挤压车间',
                value: 2,
                lineStyle:{
                  color: '#A27100',
                  opacity: 0.3,
                }
              },
              {
                source: '水',
                target: '泡磨车间',
                value: 3,
                lineStyle:{
                  color: '#A27100',
                  opacity: 0.3,
                }
              },
              {
                source: '水',
                target: '空压系统',
                value: 1,
                lineStyle:{
                  color: '#A27100',
                  opacity: 0.3,
                }
              },
              {
                source: '水',
                target: '暖通系统',
                value: 1,
                lineStyle:{
                  color: '#A27100',
                  opacity: 0.3,
                }
              },
              {
                source: '水',
                target: '辅助生产',
                value: 0.5,
                lineStyle:{
                  color: '#A27100',
                  opacity: 0.3,
                }
              },
              {
                source: '水',
                target: '办公生活',
                value: 0.5,
                lineStyle:{
                  color: '#A27100',
                  opacity: 0.3,
                }
              },
              {
                source: '电',
                target: '挤压车间',
                value: 8,
                lineStyle:{
                  color: '#55E9BE',
                  opacity: 0.3,
                }
              },
              {
                source: '电',
                target: '泡磨车间',
                value: 8,
                lineStyle:{
                  color: '#55E9BE',
                  opacity: 0.3,
                }
              },
              {
                source: '电',
                target: '空压系统',
                value: 3,
                lineStyle:{
                  color: '#55E9BE',
                  opacity: 0.3,
                }
              },
              {
                source: '电',
                target: '暖通系统',
                value:3,
                lineStyle:{
                  color: '#55E9BE',
                  opacity: 0.3,
                }
              },
              {
                source: '电',
                target: '辅助生产',
                value: 2,
                lineStyle:{
                  color: '#55E9BE',
                  opacity: 0.3,
                }
              },
              {
                source: '电',
                target: '办公生活',
                value: 1,
                lineStyle:{
                  color: '#55E9BE',
                  opacity: 0.3,
                }
              },
              {
                source: '气',
                target: '挤压车间',
                value: 3.5,
                lineStyle:{
                  color: '#11DFEA',
                  opacity: 0.3,
                }
              },
              {
                source: '气',
                target: '泡磨车间',
                value: 1.5,
                lineStyle:{
                  color: '#11DFEA',
                  opacity: 0.3,
                }
              },
              {
                source: '气',
                target: '空压系统',
                value: 2,
                lineStyle:{
                  color: '#11DFEA',
                  opacity: 0.3,
                }
              },
              {
                source: '气',
                target: '暖通系统',
                value: 2.5,
                lineStyle:{
                  color: '#11DFEA',
                  opacity: 0.3,
                }
              },
              {
                source: '气',
                target: '辅助生产',
                value: 1,
                lineStyle:{
                  color: '#11DFEA',
                  opacity: 0.3,
                }
              },
              {
                source: '气',
                target: '办公生活',
                value: 0.5,
                lineStyle:{
                  color: '#11DFEA',
                  opacity: 0.3,
                }
              },
              {
                source: '热',
                target: '挤压车间',
                value: 3,
                lineStyle:{
                  color: '#0091FF',
                  opacity: 0.3,
                }
              },
              {
                source: '热',
                target: '泡磨车间',
                value: 1.5,
                lineStyle:{
                  color: '#0091FF',
                  opacity: 0.3,
                }
              },
              {
                source: '热',
                target: '空压系统',
                value: 2,
                lineStyle:{
                  color: '#0091FF',
                  opacity: 0.3,
                }
              },
              {
                source: '热',
                target: '暖通系统',
                value: 2.5,
                lineStyle:{
                  color: '#0091FF',
                  opacity: 0.3,
                }
              },
              {
                source: '热',
                target: '辅助生产',
                value: 1,
                lineStyle:{
                  color: '#0091FF',
                  opacity: 0.3,
                }
              },
              {
                source: '热',
                target: '办公生活',
                value: 0.5,
                lineStyle:{
                  color: '#0091FF',
                  opacity: 0.3,
                }
              },
              {
                source: '新能源',
                target: '挤压车间',
                value: 0.5,
                lineStyle:{
                  color: '#947CFF',
                  opacity: 0.3,
                }
              },
              {
                source: '新能源',
                target: '泡磨车间',
                value: 4,
                lineStyle:{
                  color: '#947CFF',
                  opacity: 0.3,
                }
              },
              {
                source: '新能源',
                target: '空压系统',
                value: 2,
                lineStyle:{
                  color: '#947CFF',
                  opacity: 0.3,
                }
              },
              {
                source: '新能源',
                target: '暖通系统',
                value: 2,
                lineStyle:{
                  color: '#947CFF',
                  opacity: 0.3,
                }
              },
              {
                source: '新能源',
                target: '辅助生产',
                value: 2,
                lineStyle:{
                  color: '#947CFF',
                  opacity: 0.3,
                }
              },
              {
                source: '新能源',
                target: '办公生活',
                value: 0.5,
                lineStyle:{
                  color: '#947CFF',
                  opacity: 0.3,
                }
              },
            ],
            z: 2,
            coordinateSystem: 'view',
            left: '10%',
            top: '5%',
            right: '20%',
            bottom: '5%',
            orient: 'horizontalnode',
            nodeWidth: 10,
            nodeGap: 8,
            draggable: false,
            layoutIterations: 32,
          }
        },
        true
      )
    },
    progressecharts(){
      const curveEchart = this.$echarts.init(
        document.getElementById('progressecharts')
      )
      curveEchart.setOption(
        {
          series: [
            {
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              pointer: {
                show: false
              },
              progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                  borderWidth: 1,
                  borderColor: '#225A94',
                  color:'#44F5FE'
                }
              },
              axisLine: {
                lineStyle: {
                  width: 5,
                  color:[
                    [1,'#225A94']
                  ]
                }
              },
              splitLine: {
                show: false,
                distance: 0,
                length: 10
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                distance: 50
              },
              data: [
                {
                  value: 74.8,
                  name: 'Commonly',
                  title: {
                    offsetCenter: ['0%', '30%']
                  },
                  detail: {
                    valueAnimation: true,
                    offsetCenter: ['0%', '40%']
                  }
                }
              ],
              title: {
                fontSize: 14,
                show:false
              },
              detail: {
                width: 50,
                height: 24,
                fontSize: 16,
                fontFamily:'BoldFont',
                color: '#5CF7FF',
                borderWidth: 0,
                formatter: [
                  '{a|74.8}{b|%}',
                  '{c|限值占比}'
                ].join('\n'),
                rich:{
                  a:{
                    color:'#5CF7FF',
                    fontSize:22,
                    fontWeight:600
                  },
                  b:{
                    color:'#7EA1EF',
                    fontSize:12
                  },
                  c:{
                    color:'#fff',
                    fontSize:12,
                    lineHeight:20
                  }
                },
                padding:[-55,0,0,0]
              }
            }
          ]
        },
        true
      )
    },
    progressecharts2(){
      const curveEchart = this.$echarts.init(
        document.getElementById('progressecharts2')
      )
      curveEchart.setOption(
        {
          series: [
            {
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              pointer: {
                show: false
              },
              progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                  borderWidth: 1,
                  borderColor: '#3F92F2',
                  color:'#3F92F2'
                }
              },
              axisLine: {
                lineStyle: {
                  width: 5,
                  color:[
                    [1,'#112B5E']
                  ]
                }
              },
              splitLine: {
                show: false,
                distance: 0,
                length: 10
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                distance: 50
              },
              data: [
                {
                  value: 6.5,
                  name: 'Commonly',
                  title: {
                    offsetCenter: ['0%', '30%']
                  },
                  detail: {
                    valueAnimation: true,
                    offsetCenter: ['0%', '40%']
                  }
                }
              ],
              title: {
                fontSize: 14,
                show:false
              },
              detail: {
                width: 50,
                height: 24,
                fontSize: 16,
                color: '#5CF7FF',
                borderWidth: 0,
                fontFamily:'BoldFont',
                formatter: [
                  '{a|6.5}{b|%}',
                  '{c|利用率}'
                ].join('\n'),
                rich:{
                  a:{
                    color:'#5CF7FF',
                    fontSize:22,
                    fontWeight:600
                  },
                  b:{
                    color:'#7EA1EF',
                    fontSize:10
                  },
                  c:{
                    color:'#fff',
                    fontSize:12,
                    lineHeight:16
                  }
                },
                padding:[-55,0,0,0]
              }
            }
          ]
        },
        true
      )
    },
    LoadForecasting() {
      const curveEchart = this.$echarts.init(
        document.getElementById('LoadForecasting')
      )
      curveEchart.setOption(
        {
          color: ['#22FFCC', '#2272FF'],
          xAxis: {
            type: 'category',
            data: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00',
            '08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00',
            '17:00','18:00','19:00',
            '20:00','21:00','22:00','23:00'],
            boundaryGap:false,
            alignTicks: true,
            axisTick: {
              show: false
            },
            axisLabel:{
              interval:1,
              rotate:-40,
              color:"rgba(255, 255, 255, 0.8)"
            }
            // axisLine:{
            //   lineStyle:{
            //     color:'#999999',
            //     type:'dashed'
            //   }
            // }
            // maxInterval: 10
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          yAxis: {
            type: 'value',
            name: 'kW',
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(153, 153, 153, 0.32)"],
                width: 1,
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255, 255, 255, 0.8)",
              },
            },
          },
          series: [
            {
              name: '实时',
              type: 'line',
              smooth: true,
              lineStyle: {
                width: 1
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#19E3B4'
                  },
                  {
                    offset: 1,
                    color: '#ffffff00'
                  }
                ])
                
              },
              emphasis: {
                focus: 'series'
              },
              // data: [1000 * 3,1010 * 3,1100 * 3,1120 * 3,1150 * 3]
              data: this.filterdata(this.shishi)
            },
            {
              name: '预测',
              type: 'line',
              smooth: true,
              lineStyle: {
                width: 1
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.4,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#0066D2'
                  },
                  {
                    offset: 1,
                    color: '#0066D200'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              // data: [400 * 5, 430 * 5,490 * 5,520 * 5,600 * 5,640 * 5,670 * 5,630 * 5,590 * 5,580 * 5,560 * 5,520 * 5]
              data: this.yuce
            },
            
          ],
          legend: {
            // data: this.legenddata,
            orient: 'horizontal',
            x:'center',
            textStyle: {
              color: "#fff",
            },
            padding:[30,10,5,10]
          }
        },
        true
      )
    },
  }
}
</script>

<style  lang="less" scoped>
  @import url("~@/assets/font/font.css");
  .projectoverviewGC{
    width:100%;
    height: 100%;
    background-image: url(~@/assets/projectoverallGC/大屏罗普斯金设计.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .title{
    height: 37px;
    width: 100%;
    background: url(~@/assets/projectoverallGC/标题1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 37px;
    padding-left: 28px;
  }
  .top{
    width: 100%;
    height:  74.8%;
    display: flex;
    .left{
      width: 400px;
      height: 100%;
      // background: rgba(0, 128, 0, 0.264);
      .TotalConsumption{
        // height: 33%;
        width: 400px;
        .content{
          display: flex;
          justify-content: center;
          margin-top: 5px;
          .bmxh{
            width: 96px;
            position: relative;
            text-align: center;
            .bgimg{
              position: absolute;
              transform: translateX(-50%);
            }
            .p1,.p2,.p3{
              width: 100%;
            }
            .p1{
              font-size: 26px;
              font-weight: bold;
              color: #56F4FF;
              line-height: 30px;
              font-family: BoldFont !important;  

            }
            .p2{
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              margin-top: 2px;
            }
            .p3{
              font-size: 14px;
              font-weight: 400;
              line-height: 15px;
              background-image:-webkit-linear-gradient(bottom,#fff,#75BFFF); 
              -webkit-background-clip:text; 
              -webkit-text-fill-color:transparent; 
              margin-top: 36px;
            }
          }
          .qntb{
            margin-left: 34px;
            width: 118px;
            height: 95px;
            background-image: url(~@/assets/projectoverallGC/tongbi4.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            .p1{
              display:table-cell;
              vertical-align:bottom;
              font-size: 26px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 30px;
              font-family: BoldFont !important;
              img{
                margin-right: 8px;
              }
              span{
                font-size: 14px;
                font-weight: 400;
                color: #7EA1EF;
                line-height: 20px;
              }
            }
            .p2{
              font-size: 14px;
              font-weight: 400;
              color: #D8D8D8;
              line-height: 20px;
              text-align: center;
            }
          }
        }
        .floor{
          // height: 50px;
          // width: 306px;
          margin-left: 13px;
          margin-top: 8px;
          background: url(~@/assets/projectoverallGC/矩形.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding-left: 12px;
          padding-bottom: 6px;
          padding-top: 2px;
          .prigre{
            display: flex;
            align-items: center;
            .txt{
              margin-left: 11px;
              background-image:-webkit-linear-gradient(bottom,#fff,#75BFFF); 
              -webkit-background-clip:text; 
              -webkit-text-fill-color:transparent; 
              font-size: 14px;
              font-weight: 400;
              span{
                font-size: 16px;
                font-weight: bold;
                color: #3CE3FF;
                font-family: BoldFont !important;
              }
            }
          }
          .progress{
            width: 200px;
            height: 10px;
            background: url(~@/assets/projectoverallGC/矩形1.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 1px;
            .jindu{
              height: 8px;
              width: 70%;
              border-radius: 4px;
              background: url(~@/assets/projectoverallGC/形状结合.png);
              background-repeat: repeat;
              position: relative;
            }
            .liangd{
              height: 36px;
              width: 36px;
              background: url(~@/assets/projectoverallGC/点.png);
              background-repeat: repeat;
              transform: translate(18px,-40%);
              position: absolute;
              right: 0;
            }
            
          }
          .p1{
            background-image:-webkit-linear-gradient(bottom,#fff,#75BFFF); 
            -webkit-background-clip:text; 
            -webkit-text-fill-color:transparent; 
            font-size: 14px;
            font-weight: 400;
          }
          .p2{
            font-size: 16px;
            font-weight: bold;
            color: #FDBF7F;
            margin-left: 4px;
            margin-right: 6px;
            font-family: BoldFont !important;
          }
          .p3{
            font-size: 14px;
            font-weight: 400;
            color: #7EA1EF;
          }
        }
      }
      .CompanyConsumption{
        // margin-top: 6px;
        // height: 30%;
        margin-top: 1%;
        .content{
          padding: 8px 10px;
          display: flex;
          .headertxt{
            background: url(~@/assets/projectoverallGC/分组13.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
          }
          .l3cont{
            padding: 12px 0;
            padding-left: 12px;
            display: flex;
            .l3contP{
              margin-left: 9px;
              .p1{
                font-size: 26px;
                font-weight: bold;
                color: #47C7F8;
                line-height: 30px;
                font-family: BoldFont !important;
              }
              .p2{
                font-size: 14px;
                font-weight: 400;
                color: #7EA1EF;
                line-height: 20px;
                // font-family: BoldFont !important;
              }
            }
          }
          .left,.right{
            width: 170px;
          }
          .right{
            margin-left: 6px;
          }
        }
      }
      .GreenLowCarbon{
        height: calc(100% - 382px);
        min-height: 260px;
        margin-top: -1%;
        .contentG{
          padding:6px 12px;
          display: flex;
          flex-direction: column;
          height: calc(100% - 37px);
          justify-content: space-around;
          .titleG{
            width: 120px;
            height: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
            background: url(~@/assets/projectoverallGC/lsdt2x.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding-left: 12px;
            white-space: nowrap;
          }
          .mode1{
            .mode1content{
              width: 320px;
              height: 100px;
              background: url(~@/assets/projectoverallGC/编组27@2x.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-top: 5px;
              position: relative;
              .contentGps{
                padding-left: 12px;
                .Gp1{
                  line-height: 50px;
                  height: 50px;
                  font-size: 13px;
                  font-weight: 400;
                  color: #D8D8D8;
                  .pt1{
                    font-size: 26px;
                    font-weight: bold;
                    color: #47C7F8;
                    margin-left: 12px;
                    margin-right: 10px;
                    font-family: BoldFont !important;
                  }
                  .pt2{
                    font-size: 13px;
                    font-weight: 400;
                    color: #7EA1EF;
                  }
                }
                .Gp2{
                  line-height: 50px;
                  height: 50px;
                  font-size: 13px;
                  font-weight: 400;
                  color: #D8D8D8;
                  .pt1{
                    font-size: 26px;
                    font-weight: bold;
                    color: #FDBF7F;
                    margin-left: 12px;
                    margin-right: 10px;
                    font-family: BoldFont !important;
                  }
                  .pt2{
                    font-size: 13px;
                    font-weight: 400;
                    color: #7EA1EF;
                  }
                }
                
              }
              #progressecharts{
                background: url(~@/assets/projectoverallGC/14.webp);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 120px;
                height: 120px;
                position: absolute;
                right: 30px;
                top: -10px;
                .protxt{
                  width: 120px;
                  height: 120px;
                  line-height: 120px;
                  text-align: center;
                }
              }
            }
          }
          .mode2{
            .mode2content{
              width: 325px;
              height: 80px;
              background: url(~@/assets/projectoverallGC/编组29@2x.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-top: 5px;
              position: relative;
              .m2conts{
                height: 80px;
                padding: 0 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .m2left,.m2right{
                  .p1{
                    font-size: 26px;
                    font-weight: bold;
                    color: #47C7F8;
                    line-height: 30px;
                    font-family: BoldFont !important;
                  }
                  .p2{
                    font-size: 14px;
                    font-weight: 400;
                    color: #D8D8D8;
                    line-height: 20px;
                    span{
                      color: #7EA1EF;
                    }
                  }
                }
              }
              #progressecharts2{
                background: url(~@/assets/projectoverallGC/16.webp);
                background-size: 85% 85%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                width: 120px;
                height: 120px;
                position: absolute;
                right: 31.8%;
                top: -20px;
              }
            }
          }
        }
      }
    }
    .headercenter{
      width: calc(100% - 800px);
      height: 100%;
      // background: rgba(191, 233, 39, 0.404);
      display: flex;
      justify-content: center;
      .headeritem{
        margin-top: 10px;
        text-align: center;
        width: 184px;
        transform: translateX(-40px);
        .contentnum{
          font-size: 36px;
          font-weight: bold;
          color: #56F4FF;
          font-family: BoldFont !important;
          .Company{
            font-size: 14px;
            font-weight: 400;
            color: #7EA1EF;
            margin-left: 6px;
          }
        }
        .headimg{
          width: 174px;
          height: 37px;
          background: url(~@/assets/projectoverallGC/15.webp);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-top: 4px;
          text-align: center;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 34px;
        }
      }
    }
    .right{
      width: 400px;
      height: 100%;
      // background: rgba(128, 26, 0, 0.24);
      .jnzl{
        .jncontent{
          display: flex;
          padding: 20px 19px;
          justify-content: space-between;
          .fyleft{
            .p1{
              font-size: 36px;
              font-weight: bold;
              color: #47C7F8;
              line-height: 42px;
              font-family: BoldFont !important;
            }
            .p2{
              font-size: 14px;
              font-weight: 400;
              color: #7EA1EF;
              line-height: 20px;
            }
            .p3{
              font-size: 16px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22px;
            }
          }
          .lvright{
            width: 200px;
            height: 65px;
            background: url(~@/assets/projectoverallGC/k@2x.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            line-height: 72px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            padding-left: 30px;
            .p1{
              font-size: 34px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 40px;
              text-shadow: 0px 0px 0px #236AFF;
              font-family: BoldFont !important;
            }
            .p2{
              font-size: 14px;
              font-weight: 400;
              color: #7EA1EF;
              line-height: 20px;
              margin-left: 4px;
            }
          }
        }
      }
      .fhyc{
        width: 400px;
        height: calc((100% - 150px) * 0.4);
        #LoadForecasting{
          width: 100%;
          height: calc(100% - 37px + 64px);
          transform: translateY(-28px);
        }
      }
      .nylx{
        width: 400px;
        height: calc((100% - 150px) * 0.6);
        #EnergyFlowDirection{
          width: 100%;
          height: calc(100% - 64px + 40px);
          transform: translateY(-6px);
        }
      }
    }
  }
  .bottom{
    width: 100%;
    height: 25.2%;
    // background: rgba(255, 166, 0, 0.212);
    display: flex;
    .header{
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: space-between;
      z-index: 2;
      // padding-right: 15px;
      .title{
        width: 200px;
        height: 32px;
        background-image: url(~@/assets/projectoverallGC/110.png);
        line-height: 32px;
      }
      .tabs{
        width: calc(100% - 200px);
        height: 32px;
        background-image: url(~@/assets/projectoverallGC/111.png);
        background-repeat: repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
        .seltab{
          display: flex;
          align-items: center;
          .tab{
            width: 50px;
            height: 24px;
            background: rgba(30,56,111,0.5000);
            border: 1px solid #3F6DF8;
            line-height: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
          }
          .selthis{
            background: #3F6DF8;
          }
        }
      }
    }
    .left{
      width: 700px;
      height: 100%;
      
      .botmcont{
        width: 100%;
        height: calc(100% - 32px);
        display: flex;
        justify-content: space-around;
        align-items: center;
        .acont{
          width: 120px;
          height: 168px;
          background: url(~@/assets/projectoverallGC/底3@2x.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding: 5px 4px;
          .tophead{
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            text-align: center;
            position: relative;
            .imgh{
              position: absolute;
              width: 4px;
              height: 18px;
              left: 0;
            }
          }
          .contcont{
            width: 100%;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 10px;
            img{
              width: 62px;
              height: 70px;
            }
          }
          .context{
            width: 100%;
            text-align: center;
            font-size: 30px;
            color: #FFFFFF;
            line-height: 30px;
            font-family: BoldFont !important;
            // color: #47C7F8;
          }
        }
      }
    }
    .right{
      width: calc(100% - 700px);
      height: 100%;
      .chartMultiple{
        width: 100%;
        height: calc(100% - 32px);
      }
    }
  }
</style>